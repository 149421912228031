export default [{
  key: 'criadoEm', label: 'Data',
},
{
  key: 'formaCompra', label: 'Ação',
},
{
  key: 'quantidade', label: 'Quantidade adquirida',
},
{
  key: 'valorTotal', label: 'Valor total',
},
{
  key: 'criadoPor', label: 'Usuário',
},
]
