<template>
  <b-row>
    <b-col>
      <tabela-repeticao
        v-model="historico"
        :somente-visualizacao="true"
        :colunas="colunas"
        class="tabela-historico-compra"
      >
        <template #visualizacao="{slotScope: {key,valor}}">
          <b-badge
            v-if="key === 'formaCompra'"
            variant="light-success"
          >
            {{ valor }}
          </b-badge>
          <div
            v-else
            class="ml-50"
          >
            {{ valor }}
          </div>
        </template>
      </tabela-repeticao>
    </b-col>
  </b-row>

</template>

<script>
import colunas from './useColunas'

export default {
  name: 'VisualizacaoHistorico',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    historico: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colunas,
    }
  },

  methods: {
    visualizarGuia(id, esperar) {
      if (esperar) {
        this.iniciarEspera()
      }
      this.$emit('mostrarGuia', id)
    },
  },
}

</script>
